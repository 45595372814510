// ---------------------------------------------
// inputs, select, textarea
// ---------------------------------------------
input,
select,
textarea {
	font-size: 0.9rem !important;
	// border-radius: 0 !important;
	outline: none !important;

	&:focus {
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		outline: none !important;
	}

	&.form-control-plaintext:focus {
		border: 1px solid lighten($blue, 20%);
		padding-left: 10px;
		padding-right: 10px;
	}
}

// ---------------------------------------------
// Lighten font color for placeholder
// ---------------------------------------------
// Chrome, Firefox, Opera, Safari 10.1+
::placeholder {
  color: $color-gray-500 !important;
  opacity: 1; /* Firefox */
}

// Internet Explorer 10-11
:-ms-input-placeholder { 
  color: $color-gray-500 !important;
}

// Microsoft Edge 
::-ms-input-placeholder { 
  color: $color-gray-500 !important;
}

// ---------------------------------------------
// Default background for prepend and append
// ---------------------------------------------
.input-group-prepend,
.input-group-append {
	.input-group-text {
		// border-radius: 0;
		// background: $color-white;
	}
}

// ---------------------------------------------
// Fixed double arrow on custom-select
// ---------------------------------------------
.custom-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

// ---------------------------------------------
// Borderless table
// ---------------------------------------------
.table-borderless td,
.table-borderless th {
	border: 0;
}

// ---------------------------------------------
// Breadcrumb
// ---------------------------------------------
.breadcrumb {
	border-radius: 0;
	padding: .75rem 0;
	background-color: transparent;
	border-bottom: 1px solid $color-gray-300;
}

// ---------------------------------------------
// Media
// ---------------------------------------------
@media screen and (max-width: $screen-xs-max) {
	input,
	select,
	textarea {
		&.form-control-plaintext {
			border: 1px solid $color-gray-400;
			border-radius: .285rem;
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.theme-dark {
		input,
		select,
		textarea {
			&.form-control-plaintext {
				border: 1px solid $color-gray-800 !important;
				background: lighten($color-gray-800, 3%) !important;
			}
		}
	}
}